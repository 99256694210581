import React from 'react';
import Home from './views/Home';
import { Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';


export default function App() {
    console.log("app");
    return (

<Routes>
     <Route path="*" element={<Home/>}/>
</Routes>
    )
}
