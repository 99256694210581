import React, {useEffect, useState} from "react";
import {Col, Container, Row} from 'reactstrap';
import './Home.css';

export default class Home extends React.Component {

    componentDidMount() {
        console.log("Home did mount");
    }

    render() {

        return (< Container className={'g-0 home-container'}>

            <Row className={'g-0 home-cells'}>

                <Col xs={12}
                     md={12}
                     lg={7}>

                    <div id="leftPane">
                        <h2 className={'big-header predige-rounded off-white'}>This is NumNum Corp</h2>
                        <h2 className={'off-gray noto-sans-jp'}>これはNumNumcorpですz</h2>
                    </div>
                </Col>

                <Col xs={12}
                     md={12}
                     lg={5}>

                    <div id="rightPane">
                        <HomeCharacter/>
                    </div>
                </Col>

            </Row>
        </Container>);
    }

}


function HomeCharacter() {
    
    const [frame, setFrame] = useState(1);
    useEffect(() => {
        function handleFrameChange() {
            if (frame < 4) {
                setFrame(frame + 1);
                gotcha.src = './img/gotcha' + frame + '.png';
            }
        }

        const gotcha = document.getElementById('gotcha');
        const timer = setTimeout(() => handleFrameChange(), 2000);
        return () => clearTimeout(timer);
    }, [frame]);

    return (<div className={"rel"}>

            <div className={"home-character"}>
                <img alt="" id="gotcha" src={'./img/gotcha1.png'}/>
            </div>
        </div>

    );
}

